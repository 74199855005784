import type { LinksFunction } from '@remix-run/node';
import {
  MetaFunction,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useSearchParams,
  LiveReload,
} from '@remix-run/react';
import { useState, lazy, Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { defaultOnError, shouldRetryOnError } from '~/domain/error';
import { useStore, visibilitySlice, visibilityInitialState } from '~/store';
import { AuthModal, ReloadOnLogout } from '~/features/Auth';
import { Footer, links as footerLinks } from '~/features/Footer';
import { ConnectModal } from '~/features/Mesra';
import { ConnectFlow } from '~/features/Mesra/ConnectMesra';
import { RootNavigation, RootNavigationRoute } from '~/features/Navigation';
import { GTMScript } from './components/GTMScripts';
import { AnnouncementBar } from './features/Announcement';
import { useUpdateEffect } from '@reach/utils';
import { isMiniApp } from './shared/helpers';
import { runTimeConfig } from './shared/runtime-config';
import { AuthRequiredRouteStateProvider } from './features/Auth/AuthRequired';
import { EnableZendeskWidget } from './features/Zendesk';
import { Banner as ErrorBoundaryBanner } from './features/NotFound/NotFound';
import { ReCAPTCHAScript } from './components/ReCAPTCHA';
import { useTranslation } from 'react-i18next';
import { globalStyleHrefs, tailwindStyleHref } from './styles';
import { i18nConfig } from './i18n/config';

export const meta: MetaFunction = ({ location }) => {
  return [
    { title: 'One app, all vehicle needs | Setel' },
    { charSet: 'utf-8' },
    { name: 'viewport', content: 'width=device-width,initial-scale=1' },
    {
      name: 'description',
      content: `Experience the future of mobility. Fuel, parking, EV charging, eWallet, motor insurance, auto assistance and more, in one app for all vehicle needs`,
    },
    {
      property: 'og:url',
      content: new URL(location.pathname, 'https://www.setel.com').href,
    },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:image',
      content: `https://www.setel.com/wp-content/uploads/2022/09/OG-image_redeem-Mesra-Rewards.png`,
    },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },
    { property: 'git-hash', content: runTimeConfig.COMMIT_HASH },
    { property: 'git-hash-short', content: runTimeConfig.SHORT_COMMIT_HASH },
  ];
};

export const links: LinksFunction = () => {
  return [
    ...globalStyleHrefs.map((href) => ({
      rel: 'stylesheet',
      href,
    })),
    { rel: 'preconnect', href: 'https://www.google.com' },
    {
      rel: 'preconnect',
      href: 'https://www.gstatic.com',
      crossOrigin: 'anonymous',
    },
    /* Uses favicons from WP */
    {
      rel: 'icon',
      href: 'https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-32x32.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: 'https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-192x192.png',
      sizes: '192x192',
    },
    {
      rel: 'apple-touch-icon',
      href: 'https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-180x180.png',
    },
    ...footerLinks,
  ];
};

const Analytics = lazy(() =>
  import('~/shared/hooks/useAnalyticsTracking').then((m) => ({
    default: m.Analytics,
  }))
);

// Include a `clientLoader` to force Remix to redner `HydrateFallback` & avoid hydration error
// https://remix.run/docs/en/main/route/client-loader#hydratefallback
export const clientLoader = () => null;
clientLoader.hydrate = true;

export function HydrateFallback() {
  return (
    <Document>
      <p className="sr-only">Loading...</p>
      <Scripts />
    </Document>
  );
}

export default function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isMiniAppRoute = isMiniApp(searchParams);
  const store = useStore({ visibility: visibilityInitialState });

  useUpdateEffect(() => {
    store.dispatch(visibilitySlice.actions.closeAllExceptAuth());
  }, [location]);

  const isCardlessMesraLanding =
    location.pathname === RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE;

  return (
    <Document>
      <Provider store={store}>
        <ReloadOnLogout />
        <ReCAPTCHAScript />

        {isMiniAppRoute ? (
          <Outlet />
        ) : isCardlessMesraLanding ? (
          <EnableZendeskWidget
            KEY={runTimeConfig.ZENDESK_WIDGET_CONFIG.KEY}
            ENABLED={runTimeConfig.ZENDESK_WIDGET_CONFIG.ENABLED}
          >
            <Outlet />
          </EnableZendeskWidget>
        ) : (
          <>
            <EnableZendeskWidget
              KEY={runTimeConfig.ZENDESK_WIDGET_CONFIG.KEY}
              ENABLED={runTimeConfig.ZENDESK_WIDGET_CONFIG.ENABLED}
            >
              <AuthRequiredRouteStateProvider>
                {({ authRequiring }) => (
                  <div
                    className={`${
                      // Hides the "shell" layout ONLY on small viewport
                      // when visting auth-required route (& not authenticated).
                      // This avoids layout jump where the banner/header shown briefly
                      // then <AuthModal> quickly overlap them (due to on mount trigger)
                      authRequiring ? 'hidden md:flex' : 'flex'
                    } min-h-screen flex-col`}
                  >
                    <Suspense>
                      <AnnouncementBar />
                      <RootNavigation />
                      <Outlet />
                      <Footer className="mt-auto" />
                    </Suspense>
                  </div>
                )}
              </AuthRequiredRouteStateProvider>
            </EnableZendeskWidget>
            <AuthModal />
            <ConnectModal flow={ConnectFlow.ACTIVATE} />
            <ConnectModal flow={ConnectFlow.LINK} />
          </>
        )}
      </Provider>
    </Document>
  );
}

function Document(props: { children: React.ReactNode; title?: string }) {
  const { i18n } = useTranslation();
  const lang = i18n.language || i18nConfig.fallbackLng;

  const [queryClient] = useState(() => {
    const client = new QueryClient({
      defaultOptions: {
        mutations: {
          onError: defaultOnError,
        },
        queries: {
          onError: defaultOnError,
          refetchOnMount: false,
          retry: shouldRetryOnError,
        },
      },
    });
    return client;
  });

  return (
    <html lang={lang}>
      <head>
        <Meta />
        <Links />

        <link
          rel="stylesheet"
          href={
            /* Tailwind styles should override everything
             * thus put it below <Links> (where route-based styles are placed)
             */
            tailwindStyleHref
          }
        />
      </head>
      <body data-setel-page="">
        <QueryClientProvider client={queryClient}>
          {props.children}

          <Suspense>
            <Analytics />
          </Suspense>
        </QueryClientProvider>

        <GTMScript id={runTimeConfig.ANALYTIC.GTM.TRACKING_ID} />

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    if (
      error &&
      (error instanceof Error ||
        (typeof error === 'object' && 'message' in error))
    ) {
      defaultOnError(error);
      return;
    }
    defaultOnError(
      new Error(
        `Unexpected: root ErrorBoundary caught a thrown value: ${error}`
      )
    );
  }, [error]);

  return (
    <Document>
      <div className="h-screen flex flex-col">
        <ErrorBoundaryBanner
          title="Oh no!"
          description="Something went wrong, we are fixing it."
          className="flex-auto"
        />
      </div>
    </Document>
  );
}
